<template>
  <div>
    <b-container fluid>
      <b-row class="header pb-3">
        <b-container>
          <router-link to="/"
            ><b-col md="12"
              ><h4 v-if="$route.path == '/' || $route.path == '/store'">
                {{ texts[0] }}
              </h4>
              <h4 v-else>{{ texts[1] }}</h4></b-col
            ></router-link
          >
        </b-container>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
.header {
  padding-top: 20px;
  background-color: white;
}
h4 {
  text-transform: uppercase;
}
a {
  color: inherit;
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
</style>

<script>
import { mapState } from "vuex";
export default {
  mounted() {
    this.getTexts();
  },

  computed: {
    ...mapState(["texts", "language"]),
  },
  methods: {
    getTexts() {
      this.$store.commit("getTexts");
    },
  },
  watch: {
    language(val, oldVal) {
      if (val !== oldVal) {
        this.getTexts();
      }
    },
  },
};
</script>
