<template>
  <div id="app">
    <TheHeader />
    <transition appear name="route" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<style lang="scss">
@import url("//hello.myfonts.net/count/3d24cf");
@font-face {
  font-family: "FuturaPT-Medium";
  src: url("assets/fonts/font.woff2") format("woff2"),
    url("assets/fonts/font.woff") format("woff");
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
li,
span,
button,
b,
i {
  font-family: FuturaPT-Medium, sans-serif;
  font-weight: 500;
}
body {
  background-color: #f2f3f4 !important;
}
@media screen and (min-width: 1200px) {
  .modal-content {
    width: 598px !important;
    border-radius: 0px !important;
  }
}
.btn-custom {
  cursor: pointer;
  margin-bottom: 15px;
  position: relative;
  color: #3e3e3f;
  background: #e1e2e4;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  display: table;
  height: 100px;
  width: 100%;
  font-family: FuturaPT-Medium, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}
.btn-small {
  font-size: 14px;
  max-width: 153px;
  max-height: 41px;
  &:hover {
    transition: 0.5s;
    background: #3e3e3f;
    border: 2px solid #3e3e3f;
    text-decoration: none;
    color: #ffffff;
    outline: 0;
    opacity: 0.8;
  }
}
// Animation effect
.route-enter,
.route-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
.route-enter-active,
.route-leave-active {
  transition: all 0.3s ease;
}
.route-enter-to,
.route-leave {
  opacity: 1;
  transform: translateY(0);
}
</style>

<script>
import TheHeader from "@/components/TheHeader.vue";
export default {
  components: {
    TheHeader,
  },
};
</script>
