import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
var VueCookie = require("vue-cookie");
import VueGtm from "vue-gtm";

Vue.use(VueGtm, {
  id: "GTM-MNQWQ58", // GTM single container ID
  queryParams: {
  },
  defer: false,
  compatibility: false,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  ignoredViews: [""],
  trackOnNextTick: false,
});
Vue.use(VueCookie);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
