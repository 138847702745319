<template>
  <div>
    <b-container>
      <!-- Select Language -->
      <b-row class="mt-4 answers-wrapper">
        <VButton @click.native="changeLanguage('de')">
          DE
        </VButton>
        <VButton @click.native="changeLanguage('fr')">
          FR
        </VButton>
        <VButton @click.native="changeLanguage('it')">
          IT
        </VButton>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
.answers-wrapper {
  position: relative;
}
.active {
  ::v-deep .btn-select-lang {
    color: white;
    background: #333;
  }
}
</style>

<script>
import VButton from "@/components/VButton.vue";

export default {
  name: "SelectLanguage",
  components: {
    VButton,
  },
  methods: {
    changeLanguage(language) {
      this.$cookie.set("language", language);
      this.$store.commit("changeLanguage", language);
      this.$router.push("store").catch(() => {}); // don't catch any error because of navigationGuard is set.
    },
  },
};
</script>
