<template>
  <b-col md="3">
    <button class="btn-custom btn-select-lang">
      <slot></slot>
    </button>
  </b-col>
</template>

<style lang="scss" scoped>
.btn-custom {
  position: relative;
  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    background-image: url("../assets/check.png");
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
<script>
export default {
  name: "VButton",
};
</script>
