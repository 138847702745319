import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    answers: [],
    uniqueAnswers: [],
    options: [],
    language: "de",
    selectionIds: [],
    texts: [],
    recommendationName: "",
  },
  mutations: {
    addAnswer(state, payload) {
      state.answers.push(event.target.textContent);
      state.uniqueAnswers = [...new Set(state.answers)];
      state.options.push(payload);
    },
    setSelectionIds(state, payload) {
      state.selectionIds.push(payload);
    },
    changeLanguage(state, payload) {
      state.language = payload;
    },
    reset(state) {
      state.options = [];
      state.uniqueAnswers = [];
      state.answers = [];
      state.selectionIds = [];
    },
    clearOption(state, payload) {
      state.options = state.options.filter((e) => e !== payload);
    },
    getTexts(state) {
      Axios.get("json/texts.json").then((response) => {
        state.texts = response.data[state.language];
      });
    },
    setRecommendationName(state, payload) {
      state.recommendationName = payload;
    },
  },
  getters: {
    recommendationId(state) {
      let id = 0;
      if (state.recommendationName == "Ein-Tages-Kontaktlinsen") {
        id = 1;
      } else if (state.recommendationName == "Lentilles journalières") {
        id = 1;
      } else if (state.recommendationName == "Lenti giornaliere") {
        id = 1;
      } else if (state.recommendationName == "Monats-Kontaktlinsen") {
        id = 2;
      } else if (state.recommendationName == "Lentilles mensuelles") {
        id = 2;
      } else if (state.recommendationName == "Lenti mensili") {
        id = 2;
      } else {
        id = 3;
      }
      return id;
    },
  },
  actions: {},
  modules: {},
});
